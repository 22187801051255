import React, {Component} from 'react'


import ChristmasTourHomeTemplate from "templates/ChristmasTourHomeTemplate"
import Layout from '../../components/Layout/Layout';



class Christmas24Page extends Component {
    constructor(props) {
        super(props)

        this.state = {
            locations: [
                {
                    flag: "images/flags/uk.png",
                    place: "London",
                    when: "Now Playing",
                    where: "The Prince of Wales Theatre",
                    slug: "/london/",
                    buttonCopy: "VISIT SITE"
                },
                {
                  flag: "images/flags/uk.png",
                  place: "Manchester",
                  when: "Coming Soon",
                  where: "On Sale from 10 November",
                  slug: "/uk-eu/signup/?city=manchester",
                  buttonCopy: "FIND OUT MORE"
                },
                {
                  flag: "images/flags/uk.png",
                  place: "Edinburgh",
                  when: "Coming Soon",
                  where: "On Sale from 10 November",
                  slug: "/uk-eu/signup/?city=edinburgh",
                  buttonCopy: "FIND OUT MORE"
                },
                {
                  flag: "images/flags/uk.png",
                  place: "Birmingham",
                  when: "Coming Soon",
                  where: "On Sale from 10 November",
                  slug: "/uk-eu/signup/?city=birmingham",
                  buttonCopy: "FIND OUT MORE"
                },
                {
                    flag: "images/flags/usa.png",
                    place: "New York",
                    when: "Now Playing",
                    where: "Eugene O'Neill Theatre",
                    slug: "https://bookofmormonbroadway.com/",
                    buttonCopy: "VISIT SITE",
                    external: true
                },
                {
                    flag: "images/flags/usa.png",
                    place: "US Tour",
                    when: "Now Playing",
                    where: "Find out more",
                    slug: "https://thebookofmormontour.com/",
                    buttonCopy: "VISIT SITE",
                    external: true
                }


            ],
        }
    }

    render() {
      return <ChristmasTourHomeTemplate />;

    
    }
}

export default Christmas24Page