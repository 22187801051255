// match bootstrap sizes, also add xxl
const sizes = {
    xs: "0px",
    s: '414px',
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1440px",
    xxxl: "1920px"
};

export default {
    sizes
};