import React, { useState } from "react";
import styled from "styled-components";
import { media } from "utils/Media";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import { window } from "browser-monads";

// import '../../stylesheets/main.scss'
import '../stylesheets/main.scss'

import ButtonBackground from "../images/homepage/background.png";
import ButtonStar from "../images/homepage/star.png";
import Layout from "../components/Layout/Layout";



const date = new Date();

const buttons = [
  {
    title: "Broadway",
    href: "https://bookofmormonbroadway.com/",
  },
  {
    title: "Zurich",
    href: "/zurich/",
  },
  {
    title: "US Tour",
    href: "https://thebookofmormontour.com/",
  },
  {
    title: "AUSTRALIA",
    href: "https://thebookofmormonmusical.com.au",
  },
  {
    title: "MILAN",
    href: "https://thebookofmormonmusical.it/",
  },
];



const Header = ({ imgData }) => {
  const desktopImage = imgData.DesktopImg.childImageSharp.gatsbyImageData;
  const mobileImage = imgData.MobileImg.childImageSharp.gatsbyImageData;
  const BannerImage = imgData.BannerImg.childImageSharp.gatsbyImageData;
  return (
    <header className="welcome">
      <div className="welcome--header">
        <div className="welcome--header__imgs">
          <div className="img--mob">
            <GatsbyImage
              image={mobileImage}
              alt="The Book Of Mormon | Official Website"
            />
          </div>
        </div>
        <div className="welcome--header__desktop">
          <GatsbyImage
            image={desktopImage}
            alt="The Book Of Mormon | Official Website"
          />
        </div>
        <div className="banner">
          <GatsbyImage
            image={BannerImage}
            alt="The Book Of Mormon | Official Website"
          />
        </div>
      </div>
    </header>
  );
};

//* Footer + Buttons
const WelcomeFooter = ({ imgData, xmas }) => {
  console.log(xmas);
  const BannerImage = imgData.BannerImg.childImageSharp.gatsbyImageData;
  return (
    <div className="welcome--footer">
      <div className="welcome--footer__banner">
        <GatsbyImage
          image={BannerImage}
          alt="The Book Of Mormon | Official Website"
        />
      </div>
      <h4>Rest of the world</h4>
      <ul className="welcome--footer__list">
        {xmas && (
          <li>
            <a
              href="https://bookofmormonshop.co.uk/"
              target="_blank"
              className="btn btn-red-img"
            >
              SHOP
            </a>
          </li>
        )}
        {buttons.map((button) => (
          <li key={button.title}>
            <a href={button.href} className="btn red btn-red-img">
              {button.title}
            </a>
          </li>
        ))}
      </ul>
      <section className="welcome--footer__legal">
        <p>
          The Book Of Mormon <span>&copy; </span> {date.getFullYear()}
        </p>
        <p className="l-s">|</p>
        <p>
          <Link to="/privacy-policy/">Privacy Policy</Link>
        </p>
        <p className="l-s">|</p>
        <p>
          <Link to="/cookie-privacy/">Cookie Policy</Link>
        </p>
      </section>
    </div>
  );
};

export default function ChristmasPage({ data, xmas }) {
  const [listOpen, setListOpen] = useState(true);
  const [mapOpen, setMapOpen] = useState(false);
  const [locations, setLocations] = useState([
    {
      name: "London",
      slug: "london",
      theatre: "Palace Theatre",
      date: "Now showing",
      active: false,
    },
    {
      name: "Birmingham",
      slug: "birmingham",
      theatre: "The Alexandra",
      date: "3 - 28 December 2024",
      active: false,
    },
    {
      name: "Plymouth",
      slug: "plymouth",
      theatre: "Royal Parade",
      date: "14 – 25 January 2025",
      active: false,
      signup: false,
      new: false,
    },
    {
      name: "Zurich",
      slug: "zurich",
      theatre: "Coming soon",
      date: "4 - 23 February 2025",
      active: false,
      signup: false,
      new: false,
    },
    {
      name: "Hull",
      slug: "hull",
      theatre: "4 - 22 MARCH 2025",
      date: "4 - 22 March 2025",
      active: false,
      signup: false,
      new: false,
    },
    {
      name: "Dublin",
      slug: "dublin",
      theatre: "1 April - 3 May 2025",
      date: "1 April - 3 May 2025",
      active: false,
      signup: false,
      new: false,
    },
    {
      name: "Nottingham",
      slug: "nottingham",
      theatre: "3 - 28 June 2025",
      date: "3 - 28 June 2025",
      active: false,
      signup: false,
      new: false,
    },
    {
      name: "Cardiff",
      slug: "cardiff",
      theatre: "",
      date: "1 - 19 July 2025",
      active: false,
      signup: false,
      new: false,
    },
    {
      name: "Newcastle",
      slug: "newcastle",
      theatre: "22 July - 9 August 2025",
      date: "22 July - 9 August 2025",
      active: false,
      signup: false,
      new: false,
    },
    {
      name: "Norwich",
      slug: "norwich",
      theatre: "19 - 30 August 2025",
      date: "19 - 30 August 2025",
      active: false,
      signup: false,
      new: false,
    },
    {
      name: "Milan",
      slug: "milan",
      theatre: "10 -21 December 2025",
      date: "10 -21 December 2025",
      active: false,
      signup: false,
      new: true,
      external: 'https://thebookofmormonmusical.it/',
    },
    {
      name: "Aberdeen",
      slug: "aberdeen",
      theatre: "3 Sep – 20 Sep 2025",
      date: "3 Sep – 20 Sep 2025",
      active: true,
      signup: false,
      new: true,
    },
    {
      name: "Glasgow",
      slug: "glasgow",
      theatre: "13 - 31 May 2025",
      date: "13 - 31 May 2025",
      active: false,
      signup: false,
      new: true,
    },
    {
      name: "Liverpool",
      slug: "liverpool",
      theatre: "30 September - 18 October 2025",
      date: "30 September - 18 October 2025",
      active: false,
      signup: false,
      new: true,
    },
    {
      name: "Bristol",
      slug: "bristol",
      theatre: "21 October - 8 November 2025",
      date: "21 October - 8 November 2025",
      active: false,
      signup: false,
      new: true,
    }
  ]);
  const [index, setIndex] = useState(1);
  const [disabledPrev, setDisabledPrev] = useState(false);
  const [disabledNext, setDisabledNext] = useState(false);
  const [popUp, setPopUp] = useState(false);

  const imgData = useStaticQuery(graphql`
    query {
      DesktopImg: file(
        relativePath: { eq: "christmas/BOM-LON_Holiday24_LandingPg_Desktop_1366x497_669.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1400
            quality: 100
            placeholder: NONE
            layout: CONSTRAINED
          )
        }
      }
      MobileImg: file(relativePath: { eq: "christmas/mobile.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 576
            quality: 100
          )
        }
      }
      ListImg: file(relativePath: { eq: "welcome/doorbell.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 50
            quality: 100
            placeholder: NONE
            layout: CONSTRAINED
          )
        }
      }
      MapImg: file(relativePath: { eq: "welcome/map.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 750
            quality: 100
            placeholder: NONE
            layout: CONSTRAINED
          )
        }
      }
      BannerImg: file(relativePath: { eq: "welcome/line-desktop.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            quality: 100
            placeholder: NONE
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const togglePrev = (e) => {
    let index = index - 1;
    let disabledPrev = index === 0;

    setIndex(index);
    setDisabledNext(false);
    setDisabledPrev(disabledPrev);
  };

  const toggleNext = (e) => {
    let index = index + 1;
    let disabledNext = index === locations.length - 1;

    setIndex(index);
    setDisabledNext(disabledNext);
    setDisabledPrev(false);
  };

  /**
   * Set the new location to active
   * @param slug
   */
  const changeActive = (slug) => {
    let newLocations = locations;
    let activeLocationIndex = index;
    locations.map((location, index) => {
      if (newLocations[index].slug === slug) {
        newLocations[index].active = true;
        activeLocationIndex = index;
      } else {
        newLocations[index].active = false;
      }
    });

    setLocations(newLocations);
    setIndex(activeLocationIndex);
  };

  const toggle = () => {
    setListOpen(!listOpen);
    setMapOpen(!mapOpen);
  };

  const boxOpen = (slug, isSignup) => {
    const md = window.matchMedia("(max-width: 1200px)");
    if (md.matches) {
      changeActive(slug);
      setPopUp(true);
    } else {
      changeActive(slug);
      if (isSignup) {
        navigate(`/uk-eu/signup/?city=${slug}`);
      } else {
        navigate(`/${slug}`);
      }
    }
  };
  const boxClose = () => {
    setPopUp(false);
  };

  const getPins = locations.map((location, index) => (
    <div
      key={location.slug}
      onMouseEnter={() => changeActive(location.slug)}
      // to={`${
      //   location.signup
      //     ? `/uk-eu/signup/?city=${location.slug}`
      //     : `/${location.slug}/`
      // }`}
      onClick={() => boxOpen(location.slug, location.signup)}
      onKeyDown={() => changeActive(location.slug)}
      className={`pin pin-red pin-${location.slug} ${location.active && "active"}`}
      tabIndex={index}
      role="button"
      aria-pressed="false"
      aria-label="Open"
    />
  ));

  const getList = locations.map((location) => (
    <div
      className="listRow"
      key={location.slug}
      onMouseEnter={() => changeActive(location.slug)}
    >
      <div className="location">
        {/* <img src={location.flag} alt=""/> */}
        {location.signup ? (
          <p >
            <h3>{location.name}</h3>
          </p>
        ) : (
          <p >
            <h3>{location.name}</h3>
          </p>
        )}
        <div className="when">{location.date}</div>
      </div>
      {/* <div className="where">{location.theatre}</div> */}
      <div className="button">
        {location.signup ? (
          <>
            {location.new && (
              <>
                <button className="mobile-button">NEW DATE!</button>
                <img src={ButtonStar} alt="" className="desktop-image" />
              </>
            )}
            <Link
              to={`/uk-eu/signup/?city=${location.slug}`}
              className="btn btn-red"
            >
              Sign up
            </Link>
            {/* <Link to={`/uk-eu/signup/?city=${location.slug}`} className='btn btn-red'>Sign up</Link> */}
          </>
        ) : (
          <>
            {location.new && (
              <>
                <button className="mobile-button">NEW DATE!</button>
                <img src={ButtonStar} alt="" className="desktop-image" />
              </>
            )}
            {location.external ? (
              <a href={location.external} className="btn btn-red">
              Visit Site
            </a>
            ) : (
              <Link to={`/${location.slug}/`} className="btn btn-red">
              Visit Site
            </Link>
            )}
            
          </>
        )}
      </div>
    </div>
  ));

  return (
    <Layout metaTitle="Christmas 2024" >
      <Header imgData={imgData} />
      <div className="welcome--mbody">
        <button onClick={() => toggle()} className={mapOpen ? "activeTab" : ""}>
          Map
        </button>
        <span>|</span>
        <button
          onClick={() => toggle()}
          className={listOpen ? "activeTab" : ""}
        >
          List
        </button>
      </div>
      <section className="d-xl-none">
        {listOpen ? (
          <div className="list-styles">
            <div className="list">{getList}</div>
          </div>
        ) : (
          ""
        )}
        {mapOpen ? (
          <div className="welcome--map">
            <GatsbyImage
              image={imgData.MapImg.childImageSharp.gatsbyImageData}
              alt="The Book Of Mormon | Official Website"
            />
            {getPins}
          </div>
        ) : (
          ""
        )}
      </section>
      <div className="welcome--dbody">
        <div className="welcome--map" style={{ maxHeight: "653px" }}>
          <GatsbyImage
            image={imgData.MapImg.childImageSharp.gatsbyImageData}
            alt="The Book Of Mormon | Official Website"
          />
          {getPins}
        </div>

        <div className="welcome--list">
          <h1 className="text-red">Choose your location</h1>
          <div className="list-styles">
            <div className="list">{getList}</div>
          </div>
        </div>
      </div>
      <WelcomeFooter imgData={imgData} xmas={xmas} />
      {popUp ? (
        <div className="welcome--location">
          <div className="welcome--location--bkg" onClick={boxClose} />
          <div className="welcome--location__box">
            <button
              className="welcome--location__box--close"
              onClick={boxClose}
            />
            {/* <img src={doorbell} alt="placeholder" className="welcome--location__box--img" /> */}
            <div className="welcome--location__box--img">
              <GatsbyImage
                image={imgData.ListImg.childImageSharp.gatsbyImageData}
                alt="The Book Of Mormon | Official Website"
              />
            </div>
            <h6>{locations[index].name}</h6>
            <span>{`FROM ${locations[index].date}`}</span>
            <Link
              to={`/${locations[index].slug}/`}
              className="btn btn-red-img"
            >
              {!locations[index].signup ? "Visit Site" : "Sign up"}
            </Link>
            <div className="welcome--location__box--btns">
              {locations[index].disabled ? (
                <button disabled={true}>
                  <span>&#60;</span>
                  <p>Previous</p>
                </button>
              ) : (
                <Previous toggle={(e) => togglePrev(e)} active={disabledPrev} />
              )}
              <Next toggle={(e) => toggleNext(e)} active={disabledNext} />
            </div>
          </div>
        </div>
      ) : (
        <p className="dn">no data</p>
      )}
    </Layout>
  );
}

function Previous({ toggle, active, disabled }) {
  return (
    <button onClick={toggle} disabled={active}>
      <span>&#60;</span>
      <p>Previous</p>
    </button>
  );
}

function Next({ toggle, active }) {
  return (
    <button onClick={toggle} disabled={active}>
      <p>Next</p>
      <span>&#62;</span>{" "}
    </button>
  );
}
